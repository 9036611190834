import request from '@/utils/request'


// 查询线索打赏记录列表
export function listReward(query) {
  return request({
    url: '/clue/reward/list',
    method: 'get',
    params: query
  })
}

// 查询线索打赏记录分页
export function pageReward(query) {
  return request({
    url: '/clue/reward/page',
    method: 'get',
    params: query
  })
}

// 查询线索打赏记录详细
export function getReward(data) {
  return request({
    url: '/clue/reward/detail',
    method: 'get',
    params: data
  })
}

// 新增线索打赏记录
export function addReward(data) {
  return request({
    url: '/clue/reward/add',
    method: 'post',
    data: data
  })
}

// 修改线索打赏记录
export function updateReward(data) {
  return request({
    url: '/clue/reward/edit',
    method: 'post',
    data: data
  })
}

// 删除线索打赏记录
export function delReward(data) {
  return request({
    url: '/clue/reward/delete',
    method: 'post',
    data: data
  })
}
